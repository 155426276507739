import * as $ from 'jquery';
import svgPanZoom from 'svg-pan-zoom';
import Hammer from 'hammerjs';

export const regionMapLoad = () => {
  $(function() {
    const container = $('.regions-map');
    
    let regions = [];
    const dataScript = container.parent().find('> script');
    if (dataScript) {
      try {
        regions = JSON.parse(dataScript.html());
      } catch (e) {
        regions = [];
      }
    }

    if (container.length) {
      const zoomZone = svgPanZoom('.regions-map svg', {
        viewportSelector: '.regions-map',
        zoomEnabled: true,
        controlIconsEnabled: true,
        fit: true,
        center: true,
        contain: true,
        minZoom: 2.75,
        zoomScaleSensitivity: 0.25,
        customEventsHandler: {
          haltEventListeners: ['touchstart', 'touchend', 'touchmove', 'touchleave', 'touchcancel'],
          init: function (options) {
            // eslint-disable-next-line no-unused-vars
            var instance = options.instance, initialScale = 1, pannedX = 0, pannedY = 0;

            // Init Hammer
            // Listen only for pointer and touch events
            this.hammer = Hammer(options.svgElement, {
              inputClass: Hammer.SUPPORT_POINTER_EVENTS ? Hammer.PointerEventInput : Hammer.TouchInput
            });

            // Enable pinch
            this.hammer.get('pinch').set({enable: true})

            // Handle double tap
            this.hammer.on('doubletap', function() {
              instance.zoomIn()
            })

            // Handle pan
            this.hammer.on('panstart panmove', function(ev) {
              // On pan start reset panned variables
              if (ev.type === 'panstart') {
                pannedX = 0
                pannedY = 0
              }

              // Pan only the difference
              instance.panBy({x: ev.deltaX - pannedX, y: ev.deltaY - pannedY})
              pannedX = ev.deltaX
              pannedY = ev.deltaY
            })

            // Handle pinch
            this.hammer.on('pinchstart pinchmove', function(ev) {
              // On pinch start remember initial zoom
              if (ev.type === 'pinchstart') {
                initialScale = instance.getZoom()
                instance.zoomAtPoint(initialScale * ev.scale, {x: ev.center.x, y: ev.center.y})
              }

              instance.zoomAtPoint(initialScale * ev.scale, {x: ev.center.x, y: ev.center.y})
            })

            // Prevent moving the page on some devices when panning over SVG
            options.svgElement.addEventListener('touchmove', function(e) { e.preventDefault(); });
          },
          destroy: function() {
            if (this.hammer) {
              this.hammer.destroy();
            }
          }
        }
      });

      /* eslint-disable */
      zoomZone.zoom(2.75);
      zoomZone.setBeforePan(function(oldPan, newPan) {
        var gutterWidth = 100
          , gutterHeight = 100
          // Computed variables
          , sizes = this.getSizes()
          , leftLimit = -((sizes.viewBox.x + sizes.viewBox.width) * sizes.realZoom) + sizes.width
          , rightLimit = (sizes.viewBox.x * sizes.realZoom)
          , topLimit = -((sizes.viewBox.y + sizes.viewBox.height) * sizes.realZoom) + sizes.height
          , bottomLimit = (sizes.viewBox.y * sizes.realZoom)

        var customPan = {}
        customPan.x = Math.max(leftLimit, Math.min(rightLimit, newPan.x))
        customPan.y = Math.max(topLimit, Math.min(bottomLimit, newPan.y))

        return customPan
      })

      zoomZone.setOnZoom(function (scale) {
        container.toggleClass('regions-map--detailed', scale >= 3);
      });

      /* Find orphaned regions for debugging */
      $('.regions-map #Primary > g, .regions-map #Secondary > g').each(function () {
        let name = $(this).attr('id');
        if ($(this).attr('data-name')) {
          name = $(this).attr('data-name');
        }

        let found = false;
        let slug = "";
        for (var i = 0; i < regions.length; i++) {
          if (regions[i].title === name) {
            found = true;
            slug = regions[i].slug;
          }
        }

        $(this).click(function () {
          if (found) {
            window.location.href = `/regions/${slug}`;
          } else {
            alert("Unable to redirect to region page for " + name);
          }
        });

        if (!found) {
          console.log("Unable to locate " + name);
        }
      })
    }
  });
}