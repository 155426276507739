import Isotope from 'isotope-layout';
import imagesLoaded from 'imagesloaded'
import 'lightgallery.js';
import lgVideo from 'lg-video.js';

const galleryModule = {
  init(el) {
    const isotope = new Isotope(el, {
      itemSelector: '.masonry-item',
      percentPosition: true,
      masonry: {
        columnWidth: '.grid-sizer'
      }
    });

    const imgLoad = imagesLoaded(el);
    imgLoad.on('progress', function () {
      isotope.layout();
    });

    // eslint-disable-next-line no-undef
    lightGallery(el, {
      selector: '.masonry-item a'
    });

    return isotope;
  },

  youtubeLightbox(el) {
    // eslint-disable-next-line no-undef
    lightGallery(el, {
      plugins: [lgVideo],
      youTubePlayerParams: {
        modestbranding : 1,
        showinfo : 0,
        controls : 0
      }
    });
  }
};

export { galleryModule };