import * as $ from 'jquery';
window.$ = $;

import Alpine from 'alpinejs';

// Plugins
import 'slick-carousel';

document.documentElement.classList.remove('no-js');

// Modules

import { cookiePolicy } from './modules/cookiePolicy';
import { aosLoad } from "./modules/aos";
import { galleryModule } from "./modules/gallery";
import { regionMapLoad } from "./modules/regionMap";

// Exports

cookiePolicy(() => {
  if (window.gtmInit) {
    window.gtmInit();
  }
});

aosLoad();
regionMapLoad();

// Global Exports

window.Gallery = galleryModule;

// Init Page

window.Alpine = Alpine;
Alpine.start();